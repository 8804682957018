import React from "react"

import data from "../data/data"

import Layout from "../components/layout/layout.component"
import SEO from "../components/seo/seo.component"

const IndexPage = ({ title }) => (
  <Layout>
    <SEO title={title} />
  </Layout>
)

IndexPage.defaultProps = {
  ...data,
}

export default IndexPage
